import { navigate } from "gatsby"
export default ({ pageContext: { slug, locale } }) => {
  let to = locale + slug
  // avoid double trailing slash on index page.
  to = to.endsWith("/") ? to : to + "/"
  if (typeof window !== "undefined" && !slug.match(/^\/paymentConfirmation/)) {
    navigate(to, { replace: true })
  }
  return null
}
